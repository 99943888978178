import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip as RechartsTooltip } from 'recharts';
import { db } from '../../../helpers/firebase';
import { collection, doc, getAggregateFromServer, getDoc, getDocs, query, sum, where } from 'firebase/firestore';
import { useTopbar } from '../../../helpers/TopbarContext';
import useAuth from '../../../hooks/useAuth';
import az from '../../../assets/merchantspring/az.jpg';
import ml from '../../../assets/merchantspring/ml.jpg';
import mx from '../../../assets/merchantspring/mx.svg';

// Función auxiliar para formatear los números a formato de moneda
const formatCurrency = (value) => {
    if (!value) {
        return;
    }
    return value.toLocaleString('es-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}

// Componente de Tooltip personalizado
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <div className="bg-kompamx-800 text-white p-2 border-2 rounded-md shadow-lg border-kompamx-300">
                <p className="label">{`${data.name}: ${(data.value / data.total * 100).toFixed(2)}%`}</p>
            </div>
        );
    }

    return null;
};

const getInitials = (name) => {
    return name
        .split(' ')
        .map(word => word[0])
        .join('. ');
};

// // Componente de leyenda personalizado
const RenderLegend = ({ payload, chartWidth }) => {

    return <ul className="recharts-default-legend px-2 py-2 ">
        {payload.map((entry, index) => {
            // justify-center if chartWidth > respondAtChartWidth
            return (
                <li key={`item-${index}`} className="flex flex-row text-sm justify-start mb-2 ">
                    <svg height="10" width="10" className="mr-2 mt-2">
                        <rect width="10" height="10" style={{ fill: entry.color, strokeWidth: 1, stroke: "black" }} />
                    </svg>
                    <div className="flex flex-col">
                        <Tooltip title={chartWidth < 500 ? entry.payload.name : ''}>
                            <span className="text-black">
                                {entry.payload.logo && (
                                    <img
                                        src={entry.payload.logo}
                                        alt={entry.payload.name}
                                        className="inline-block w-4 h-4 mr-1"
                                    />
                                )}
                                {entry.payload.mxLogo && (
                                    <img
                                        src={entry.payload.mxLogo}
                                        alt={entry.payload.name}
                                        className="inline-block w-4 h-4 mr-1"
                                    />
                                )}
                                {" "}{chartWidth < 500 ? getInitials(entry.payload.name) : entry.payload.name}
                            </span>
                        </Tooltip>
                        <span className="text-left">
                            {formatCurrency(entry.payload.value) || "$0.00"}
                            <span className="text-gray-400">
                                {" "}
                                {`(${(entry.payload.percent * 100).toFixed(2)}%)`}
                            </span>
                        </span>
                    </div>
                </li>
            );
        }
        )}
    </ul>;
};

const SalesMixChart = ({ storeIds, productsProp }) => {
    const [data, setData] = useState([]);
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? ((window.innerWidth - 15)) : ((window.innerWidth * 0.5) - 50));
    const [loading, setLoading] = useState(true);

    const {
        startDate,
        endDate,
        selectedTimezone
    } = useTopbar();

    console.log('SalesMixChart selectedTimezone: ', selectedTimezone);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setChartWidth((window.innerWidth - 15));
                return;
            }
            const newChartWidth = ((window.innerWidth * 0.5) - 50);
            setChartWidth(newChartWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            if (!currentUser) return;

            setLoading(true);
            const uid = currentUser?.uid;
            if (!uid) return;

            const storesRef = collection(db, `users/${uid}/stores`);
            let storesData = [];
            let totalValueAllStores = 0;

            if (productsProp && productsProp.length > 0) {
                // Logic for handling specific products
                let storeIds = [];
                for (const product of productsProp) {
                    if (!product || !product.storeId) continue;

                    const productRef = doc(db, `users/${uid}/stores/${product.storeId}/listings/${product.id}`);
                    const productDoc = await getDoc(productRef);

                    if (!productDoc.exists()) {
                        continue;
                    }

                    const storeDoc = await getDoc(doc(storesRef, product.storeId));
                    const { custom_name, color } = storeDoc.data();
                    const prefix = product.storeId.split('_')[0];
                    const logo = prefix === 'ml' ? ml : prefix === 'az' ? az : '';
                    const mxLogo = custom_name !== 'Otras tiendas' ? mx : '';

                    const campoVentas = productDoc.data()?.ventas || 0;
                    const campoPrice = productDoc.data()?.price || 0;
                    const campoSold_quantity = productDoc.data()?.sold_quantity || 0;
                    const productValue = campoVentas || (campoPrice * campoSold_quantity) || 0;

                    const existingStoreIndex = storesData.findIndex(store => store.id === product.storeId);
                    if (existingStoreIndex !== -1) {
                        storesData[existingStoreIndex].value += productValue;
                    } else {
                        storesData.push({
                            name: custom_name,
                            value: productValue,
                            logo,
                            mxLogo,
                            id: product.storeId,
                            color: color || 'black'
                        });
                    }

                    totalValueAllStores += productValue;
                    if (!storeIds.includes(product.storeId)) {
                        storeIds.push(product.storeId);
                    }
                }

                // Filter storesData to only show the stores with the selected products
                storesData = storesData.filter(store => storeIds.includes(store.id));
            } else {
                // Logic for handling all stores or specific storeIds
                const storesToProcess = storeIds && storeIds.length > 0 ? storeIds : [];

                if (storesToProcess.length === 0) {
                    const storesSnapshot = await getDocs(storesRef);
                    storesToProcess.push(...storesSnapshot.docs.map(doc => doc.id));
                }

                for (let storeId of storesToProcess) {
                    const storeDoc = await getDoc(doc(storesRef, storeId));
                    const { custom_name, color } = storeDoc.data();
                    const prefix = storeId.split('_')[0];
                    const logo = prefix === 'ml' ? ml : prefix === 'az' ? az : '';
                    const mxLogo = custom_name !== 'Otras tiendas' ? mx : '';

                    const ordersRef = collection(db, `users/${uid}/stores/${storeId}/orders`);
                    const q = query(
                        ordersRef,
                        where('date_timestamp', '>=', startDate),
                        where('date_timestamp', '<=', endDate)
                    );
                    const aggregateSnapshot = await getAggregateFromServer(q, {
                        totalSales: sum('total_float')
                    });

                    const storeTotal = aggregateSnapshot.data().totalSales;
                    totalValueAllStores += storeTotal;

                    storesData.push({
                        name: custom_name,
                        value: storeTotal,
                        logo,
                        mxLogo,
                        id: storeId,
                        color: color || 'black'
                    });
                }
            }

            // Update percentages and totals
            storesData = storesData.map(store => ({
                ...store,
                percent: totalValueAllStores ? (store.value / totalValueAllStores) : 0,
                total: totalValueAllStores
            }));

            // Sort data from highest to lowest value
            storesData.sort((a, b) => b.value - a.value);

            setData(storesData);
            setLoading(false);
        };

        fetchData();
    }, [startDate, endDate, storeIds, productsProp, currentUser]);


    const totalValue = useMemo(() => {
        return data.reduce((acc, doc) => acc + (doc.value || 0), 0);
    }, [data]);

    const memoizedChartData = useMemo(() => {
        return data.map(doc => ({
            ...doc,
            percent: totalValue ? ((doc.value || 0) / totalValue) : 0,
            total: totalValue
        }));
    }, [data, totalValue]);

    return (
        <div className="flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1" style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
            <div className="flex flex-row px-5 items-center w-full text-kompamxblue text-base" style={{ minHeight: "3.5rem" }}>

                <div className="font-semibold mr-2">
                    Mezcla de ventas
                </div>

                <Tooltip title="Acciones que cada una de sus tiendas aportan a sus ventas totales.">
                    <InfoOutlined style={{ fontSize: 18 }} className='ml-auto' />
                </Tooltip>

            </div>
            <hr className="w-full border-gray-300" />
            {
                loading ? <div className="flex items-center justify-center w-full h-full">
                    <div className='spinner' />
                </div> : memoizedChartData.length === 0 ?
                    <div className="flex justify-center items-center h-full w-full">
                        <p className="text-gray-500 text-lg">No hay datos para mostrar</p>
                    </div> :
                    <div className="flex flex-col items-center justify-center h-full w-full">
                        <PieChart
                            width={chartWidth}
                            height={300}
                            className=""
                        >
                            <Pie
                                data={memoizedChartData}
                                outerRadius={(chartWidth) > 550 ? 100 : (chartWidth / 8)}
                                cx={(chartWidth) > 550 ? ((chartWidth / 5) + 25) : (chartWidth / 6)}
                                cy={150}
                                fill="#8884d8"
                                dataKey="value"
                                className={(chartWidth) > 450 ? '' : ''}
                            >

                                {memoizedChartData.map((entry, index) => {
                                    return <Cell key={`cell-${index}`} fill={entry.color} />;
                                })}
                            </Pie>
                            <Legend
                                layout="vertical"
                                align="right"
                                verticalAlign="middle"
                                content={(props) => <RenderLegend {...props} chartWidth={chartWidth} />}
                            />

                            <RechartsTooltip content={<CustomTooltip />} />
                        </PieChart>
                    </div>
            }
        </div >
    );
};

export default SalesMixChart;
