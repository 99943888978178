'use client'

import React, { useId, useRef, useState, useEffect, useDimensions, useCallback } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import clsx from 'clsx'

import { Contenedor } from './Contenedor'
import screenshotCompetidores from '../imagenes/capturas/contacts.png'
import screenshotInventario from '../imagenes/capturas/inventory.png'
import screenshotVentas from '../imagenes/capturas/profit-loss.png'
import useResizeObserver from '@react-hook/resize-observer';

const caracteristicas = [
  {
    nombre: 'Análisis de Ventas',
    resumen: 'Mantente al día con informes de ventas siempre actualizados.',
    descripcion:
      'Nuestros informes detallados te permiten visualizar tendencias de ventas, identificar tus productos más rentables y tomar decisiones informadas para impulsar tu crecimiento.',
    imagen: screenshotVentas,
    icono: function IconoVentas() {
      let id = useId()
      return (
        <>
          <defs>
            <linearGradient
              id={id}
              x1="11.5"
              y1={18}
              x2={36}
              y2="15.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset=".194" stopColor="#fff" />
              <stop offset={1} stopColor="#6692F1" />
            </linearGradient>
          </defs>
          <path
            d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5"
            stroke={`url(#${id})`}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    },
  },
  {
    nombre: 'Gestión de Inventario',
    resumen: 'Nunca pierdas de vista tu stock con un seguimiento preciso del inventario.',
    descripcion:
      'Optimiza tu inventario con alertas de stock bajo, previsiones de demanda y sincronización en tiempo real con tus plataformas de venta en línea.',
    imagen: screenshotInventario,
    icono: function IconoInventario() {
      return (
        <>
          <path
            opacity=".5"
            d="M8 17a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
          <path
            opacity=".3"
            d="M8 24a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
          <path
            d="M8 10a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
        </>
      )
    },
  },
  {
    nombre: 'Análisis de Competidores',
    resumen: 'Monitorea a tus competidores y mantente un paso adelante en el mercado.',
    descripcion:
      'Analiza precios, rankings y estrategias de tus competidores para identificar oportunidades de mejora y optimizar tu posicionamiento en el mercado.',
    imagen: screenshotCompetidores,
    icono: function IconoCompetidores() {
      return (
        <>
          <path
            opacity=".5"
            d="M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75.357.421.993.419 1.384.028.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z"
            fill="#fff"
          />
          <path
            d="M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5 1.343 3.5 3 3.5 3-1.567 3-3.5Z"
            fill="#fff"
          />
        </>
      )
    },
  },
]

function Caracteristica({ caracteristica, esActiva, className, ...props }) {
  return (
    <div
      className={clsx(className, !esActiva && 'opacity-75 hover:opacity-100')}
      {...props}
    >
      <div
        className={clsx(
          'w-9 rounded-lg',
          esActiva ? 'bg-blue-600' : 'bg-slate-500',
        )}
      >
        <svg aria-hidden="true" className="h-9 w-9" fill="none">
          <caracteristica.icono />
        </svg>
      </div>
      <h3
        className={clsx(
          'mt-6 text-sm font-medium',
          esActiva ? 'text-blue-600' : 'text-slate-600',
        )}
      >
        {caracteristica.nombre}
      </h3>
      <p className="mt-2 font-display text-xl text-slate-900">
        {caracteristica.resumen}
      </p>
      <p className="mt-4 text-sm text-slate-600">{caracteristica.descripcion}</p>
    </div>
  )
}

function CaracteristicasMovil() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {caracteristicas.map((caracteristica) => (
        <div key={caracteristica.resumen}>
          <Caracteristica caracteristica={caracteristica} className="mx-auto max-w-2xl" esActiva />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" />
            <div className="relative mx-auto w-full overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
              <img
                className="w-full"
                src={caracteristica.imagen}
                alt=""
              />
            </div>
          </div>
        </div>
      ))
      }
    </div >
  )
}


function CaracteristicasEscritorio() {
  return (
    <TabGroup className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <TabList className="grid grid-cols-3 gap-x-8">
            {caracteristicas.map((caracteristica, indiceCaracteristica) => (
              <Caracteristica
                key={caracteristica.resumen}
                caracteristica={{
                  ...caracteristica,
                  nombre: (
                    <Tab className="ui-not-focus-visible:outline-none outline-none">
                      <span className="absolute inset-0" />
                      {caracteristica.nombre}
                    </Tab>
                  ),
                }}
                esActiva={indiceCaracteristica === selectedIndex}
                className="relative"
              />
            ))}
          </TabList>
          <TabPanels className="relative mt-20 overflow-hidden rounded-[2rem]  bg-slate-200 px-14 py-16 xl:px-16 rounded-4xl ring-1 ring-inset ring-slate-900/10">
            <div className="-mx-5 flex">
              {caracteristicas.map((caracteristica, indiceCaracteristica) => (
                <TabPanel
                  static
                  key={caracteristica.resumen}
                  className={clsx(
                    'px-5 transition duration-500 ease-in-out ui-not-focus-visible:outline-none',
                    indiceCaracteristica !== selectedIndex && 'opacity-60',
                  )}
                  style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                  aria-hidden={indiceCaracteristica !== selectedIndex}
                >
                  <div className="w-[52.75rem] overflow-hidden rounded-xl bg-white">
                    <img
                      className="w-full"
                      src={caracteristica.imagen}
                      alt=""
                      sizes="52.75rem"
                    />
                  </div>
                </TabPanel>
              ))}
            </div>
            <div className="pointer-events-none absolute inset-0 " />
          </TabPanels>
        </>
      )}
    </TabGroup>
  )
}

export function CaracteristicasSecundarias() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section
      id="caracteristicas-secundarias"
      aria-label="Características para simplificar las tareas diarias de tu negocio en línea"
      className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32"
      style={{ maxWidth: '100vw' }}
    >
      <Contenedor>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="text-slate-900 text-[2.45rem]">
            Simplifica las tareas diarias de tu e-commerce.
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Porque en Kompa creemos que gestionar tu negocio en línea debería ser fácil y eficiente.
          </p>
        </div>
        <CaracteristicasMovil />
        <CaracteristicasEscritorio />
      </Contenedor>
    </section>
  )
}