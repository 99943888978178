import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react'
import { auth, db, functions } from '../../helpers/firebase';
import AmazonAnimation from '../../components/AmazonAnimation';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import './amazonConnect.css';
import Footer from '../../components/Footer';
import { useTopbar } from '../../helpers/TopbarContext';

const colores = [
    "#2987f9",
    "#08c3f5 ",
    "#8800c8",
    "#029dfc ",
    "#47107d",
    "#84007f"
]

export default function AmazonConnect({ stores, setStores, hideSidebar, setHideSidebar }) {
    const [loadingTokenData, setLoadingTokenData] = useState(true);
    const [amazonCode, setAmazonCode] = useState(null);
    const [tokenData, setTokenData] = useState(null);
    const [apiCalled, setApiCalled] = useState(false);
    const [isAmazonAds, setIsAmazonAds] = useState(false);
    const { setShouldSidebarBeOpen } = useTopbar();

    useEffect(() => {
        document.title = 'Amazon Connect | Kompa';
    }, []);

    const [lastScrollTop, setLastScrollTop] = useState(0);

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop;  // Obtiene la posición actual del scroll
        if (scrollTop < lastScrollTop) {
            // scrollear hasta arriba el big-container, suavemente
            const bigContainer = document.getElementById('big-container');
            bigContainer.scrollTop = 0;

        }
        setLastScrollTop(scrollTop);  // Actualiza la última posición del scroll
    };

    useEffect(() => {
        const contentToPdf = document.getElementById('content-to-pdf');
        contentToPdf.addEventListener('scroll', handleScroll);

        return () => {
            contentToPdf.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);  // Dependencias en las que el efecto depende

    useEffect(() => {

        const exchangeAmazonCodeForToken = async (code, sellingPartnerId, marketplaceId, regionUrl) => {
            if (!code) {
                setLoadingTokenData(false);
                return;
            }
            if (!sellingPartnerId) {
                setLoadingTokenData(false);
                return;
            }

            if (tokenData) return;

            setLoadingTokenData(true);
            const exchangeFunction = httpsCallable(functions, 'exchangeAmazonCode');
            try {
                const result = await exchangeFunction({ code, sellingPartnerId, marketplaceId, regionUrl });
                setTokenData(result.data.data);
                setHideSidebar(false);
                setLoadingTokenData(false);
                setShouldSidebarBeOpen(true);
            } catch (error) {
                setHideSidebar(false);
                setLoadingTokenData(false);
                console.error('Error al intercambiar el código:', error);
                console.log(error.message);
            }
        };

        const exchangeAmazonAdsCodeForToken = async (code) => {
            if (!code) return;
            // if (loadingTokenData) return;
            if (tokenData) return;


            setLoadingTokenData(true);
            const exchangeFunction = httpsCallable(functions, 'exchangeAmazonAdsCode');
            try {
                const result = await exchangeFunction({ code });
                setTokenData(result.data.data);
                setHideSidebar(false);
                setLoadingTokenData(false);
                setShouldSidebarBeOpen(true);
            } catch (error) {
                setHideSidebar(false);
                setLoadingTokenData(false);
                console.error('Error al intercambiar el código:', error);
            }
        }

        const fetchData = async () => {
            setLoadingTokenData(true);
            try {
                const marketplaceMappings = {
                    'CA': { marketplaceId: 'A2EUQ1WTGCTBG2', regionUrl: 'https://sellingpartnerapi-na.amazon.com' },
                    'US': { marketplaceId: 'ATVPDKIKX0DER', regionUrl: 'https://sellingpartnerapi-na.amazon.com' },
                    'MX': { marketplaceId: 'A1AM78C64UM0Y8', regionUrl: 'https://sellingpartnerapi-na.amazon.com' },
                    'BR': { marketplaceId: 'A2Q3Y263D00KWC', regionUrl: 'https://sellingpartnerapi-na.amazon.com' },
                    'ES': { marketplaceId: 'A1RKKUPIHCS9HS', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'GB': { marketplaceId: 'A1F83G8C2ARO7P', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'FR': { marketplaceId: 'A13V1IB3VIYZZH', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'BE': { marketplaceId: 'A1PA6795UKMFR9', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'NL': { marketplaceId: 'A1805IZSGTT6HS', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'DE': { marketplaceId: 'A1PA6795UKMFR9', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'IT': { marketplaceId: 'APJ6JRA9NG5V4', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'SE': { marketplaceId: 'A2NODRKZP88ZB9', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'ZA': { marketplaceId: 'A2VIGQ35RCS4UG', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'PL': { marketplaceId: 'A1C3SOZRARQ6R3', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'EG': { marketplaceId: 'ARBP9OOSHTCHU', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'TR': { marketplaceId: 'A33AVAJ2PDY3EV', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'SA': { marketplaceId: 'A17E79C6D8DWNP', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'AE': { marketplaceId: 'A2VIGQ35RCS4UG', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'IN': { marketplaceId: 'A21TJRUUN4KGV', regionUrl: 'https://sellingpartnerapi-eu.amazon.com' },
                    'SG': { marketplaceId: 'A19VAU5U5O7RUS', regionUrl: 'https://sellingpartnerapi-fe.amazon.com' },
                    'AU': { marketplaceId: 'A39IBJ37TRP1C6', regionUrl: 'https://sellingpartnerapi-fe.amazon.com' },
                    'JP': { marketplaceId: 'A1VC38T7YXB528', regionUrl: 'https://sellingpartnerapi-fe.amazon.com' },
                };

                const queryParams = new URLSearchParams(window.location.search);
                const state = queryParams.get('state');

                if (state in marketplaceMappings) {
                    const { marketplaceId, regionUrl } = marketplaceMappings[state];

                    const spapi_oauth_code = queryParams.get('spapi_oauth_code');
                    const sellingPartnerId = queryParams.get('selling_partner_id');
                    const code = queryParams.get('code');
                    if (spapi_oauth_code && sellingPartnerId) {
                        setAmazonCode(spapi_oauth_code);
                        await exchangeAmazonCodeForToken(spapi_oauth_code, sellingPartnerId, marketplaceId, regionUrl);

                        // get user stores from users/{userId}/stores
                        const user = auth.currentUser;
                        const userId = user.uid;
                        const storesCollection = collection(db, `users/${userId}/stores`);
                        const storesSnapshot = await getDocs(storesCollection);
                        const storesData = storesSnapshot.docs.map(doc => {
                            return {
                                id: doc.id,
                                ...doc.data()
                            };
                        });

                        // count where store id starts with az_
                        const numberOfAmazonStores = storesData.filter(store => store.id.startsWith('az_')).length;

                        // `users/${auth.currentUser.uid}/stores/az_${sellingPartnerId}`
                        const storeRef = doc(db, `users/${auth.currentUser.uid}/stores/az_${sellingPartnerId}`);
                        const storeData = {
                            marketplaceId,
                            regionUrl,
                            countryCode: state,
                            color: numberOfAmazonStores === 0 ? '#FF9900' : colores[numberOfAmazonStores % colores.length],
                        };

                        await setDoc(storeRef, storeData, { merge: true });

                        // get stores again
                        const storesSnapshotAfter = await getDocs(storesCollection);
                        const storesDataAfter = storesSnapshotAfter.docs.map(doc => {
                            return {
                                id: doc.id,
                                ...doc.data()
                            };
                        });

                        setStores(storesDataAfter);
                    } else if (code) {
                        setIsAmazonAds(true);
                        setAmazonCode(code);
                        await exchangeAmazonAdsCodeForToken(code);
                        setHideSidebar(false);
                        // get user stores from users/{userId}/stores
                        const user = auth.currentUser;
                        const userId = user.uid;
                        const storesCollection = collection(db, `users/${userId}/stores`);
                        const storesSnapshot = await getDocs(storesCollection);
                        const storesData = storesSnapshot.docs.map(doc => doc.data());
                        setStores(storesData);
                    } else {
                        setAmazonCode(null);
                        setLoadingTokenData(false);
                    }
                } else {
                    console.log('No se encontró el estado');
                    setAmazonCode(null);
                    setLoadingTokenData(false);
                }
            } catch (error) {
                console.error('Error al obtener los datos:', error);
                console.log(error.message);
                setAmazonCode(null);
                setLoadingTokenData(false);
            } finally {
                setApiCalled(true);
                setHideSidebar(false);
                setLoadingTokenData(false);
            }
        }

        if (apiCalled) return;
        setApiCalled(true);
        setHideSidebar(true);
        fetchData();
    }, []);

    return (
        <div id="big-container" style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>
            <div
                id='content-to-pdf'
                className={`flex flex-col justify-center items-center bg-gray-100 py-5 px-5 ${((stores && stores.length && stores.length > 0) && !hideSidebar) && ""}`}
                style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}
            >
                <AmazonAnimation animationState={loadingTokenData ? 'loading' : (tokenData && tokenData.access_token && tokenData.refresh_token ? 'success' : 'error')} />

                {amazonCode ?
                    (!loadingTokenData ?
                        <h1
                            className="mb-8 text-center w-1/2 text-green-500"
                            style={{
                                fontWeight: 'bold',
                                display: 'inline-block',
                                fontFamily: 'monospace',
                                fontSize: '30px'
                            }}
                        >
                            {tokenData && tokenData.access_token && tokenData.refresh_token &&
                                <>
                                    {(isAmazonAds ? '¡Conexión con Amazon Ads exitosa!' : '¡Conexión con Amazon exitosa!')}
                                    <p className="text-gray-400 text-sm block font-normal mt-4">
                                        Recibirás un correo electrónico cuando se haya terminado de traer la información de tu tienda.
                                    </p>
                                </>
                            }
                        </h1> :
                        <div className="flex justify-center items-center">
                            <h1 className="text-kompamxblue font-bold mb-8 text-center w-full loading">
                                {isAmazonAds ? 'Conectando Amazon Ads' : 'Conectando con Amazon'}...
                            </h1>
                        </div>
                    ) :
                    <h1 className="mb-8 text-center text-red-500 w-1/2" style={{
                        fontWeight: 'bold',
                        display: 'inline-block',
                        fontFamily: 'monospace',
                        fontSize: '30px'
                    }}>
                        ¡Conexión con Amazon fallida!
                    </h1>
                }

                {!amazonCode &&
                    <p className="text-2xl font-medium text-center text-red-500">
                        No se pudo obtener el código de Amazon.
                    </p>

                }
                {
                    !loadingTokenData && amazonCode &&
                    !(tokenData && tokenData.access_token && tokenData.refresh_token) &&
                    <p className="text-2xl font-medium text-center text-red-500">
                        Hubo un problema obteniendo el token, por favor intente de nuevo.
                    </p>
                }
            </div >
            <Footer />
        </div >
    );
}
