import React, { useState, useEffect } from 'react';
import SalesMixChart from '../../components/charts/pie/SalesMixChart';
import DragIndicator from '@mui/icons-material/DragIndicator';
import { auth, db } from '../../helpers/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Checkbox, Divider, Menu, MenuItem } from '@mui/material';
import CustomMenu from '../../components/CustomMenu';
import StoreComparisonChart from '../../components/charts/bar/StoreComparisonChart';
import MostSoldProducts from '../../components/charts/MostSoldProducts';
import TrafficConversionChart from '../../components/charts/TrafficConversionChart';
import MeLiMetrics from '../../components/charts/MeLiMetrics';
import StorePerformanceChart from '../../components/charts/StorePerformanceChart';
import PeriodComparisonChart from '../../components/charts/bar/PeriodComparisonChart';
import AdMetricsChart from '../../components/charts/AdMetricsChart';
import Footer from '../../components/Footer';

export default function Tablero({ stores }) {
    const [charts, setCharts] = useState([
        { id: 'salesMix1', type: 'SalesMixChart', content: <SalesMixChart />, visible: true, label: 'Mezcla de ventas 1', props: {} },
        { id: 'barChart1', type: 'BarChartComponent', content: <StoreComparisonChart />, visible: true, label: 'Comparativa de Ventas Anuales 1', props: { metricProp: 'Ventas' } },
        { id: 'barChart2', type: 'BarChartComponent', content: <StoreComparisonChart />, visible: true, label: 'Comparativa de Pedidos Anuales 1', props: { metricProp: 'Pedidos' } },
        { id: 'barChart3', type: 'BarChartComponent', content: <StoreComparisonChart />, visible: true, label: 'Comparativa de Valor Promedio de Pedido 1', props: { metricProp: 'AOV' } },
        { id: 'barChart4', type: 'BarChartComponent', content: <StoreComparisonChart />, visible: true, label: 'Comparativa de Unidades Vendidas 1', props: { metricProp: 'Unidades' } },
        { id: 'barChart5', type: 'BarChartComponent', content: <StoreComparisonChart />, visible: true, label: 'Comparativa de Total Acumulado 1', props: { metricProp: 'TotalAcumulado' } },
        { id: 'mostSold1', type: 'MostSoldProducts', content: <MostSoldProducts />, visible: true, label: 'Productos más vendidos 1', props: { sortingTypeProp: 'desc' } },
        { id: 'mostSold2', type: 'MostSoldProducts', content: <MostSoldProducts />, visible: true, label: 'Productos menos vendidos 1', props: { sortingTypeProp: 'asc' } },
        { id: 'trafficConversion1', type: 'TrafficConversionChart', content: <TrafficConversionChart />, visible: true, label: 'Tráfico y Conversión 1', proprs: {} },
        { id: 'MeLiMetrics1', type: 'MeLiMetrics', content: <MeLiMetrics />, visible: true, label: 'Métricas de Mercado Libre 1', props: {} },
        { id: 'storePerformanceChart1', type: 'StorePerformanceChart', content: <StorePerformanceChart />, visible: true, label: 'Rentabilidad General', props: {} },
        { id: 'storePerformanceChart2', type: 'StorePerformanceChart', content: <StorePerformanceChart />, visible: true, label: 'Rentabilidad de Anuncios', props: { store: null, onlyAdsSales: true } },
        { id: 'periodComparison1', type: 'PeriodComparison', content: <PeriodComparisonChart />, visible: true, label: 'Comparativa de Periodos 1', props: { store: null, ordersGraph: false } },
        { id: 'periodComparison2', type: 'PeriodComparison', content: <PeriodComparisonChart />, visible: true, label: 'Comparativa de Periodos 2', props: { store: null, ordersGraph: true } },
        { id: 'adMetricsChart1', type: 'AdMetricsChart', content: <AdMetricsChart />, visible: true, label: 'ACoS 1', props: { selectedOptionProp: { value: 'acos', label: 'ACoS' } } },
        { id: 'adMetricsChart2', type: 'AdMetricsChart', content: <AdMetricsChart />, visible: true, label: 'ROAS 1', props: { selectedOptionProp: { value: 'roas', label: 'ROAS' } } },
        { id: 'adMetricsChart3', type: 'AdMetricsChart', content: <AdMetricsChart />, visible: true, label: 'TACoS 1', props: { selectedOptionProp: { value: 'tacos', label: 'TACoS' } } },
        { id: 'adMetricsChart4', type: 'AdMetricsChart', content: <AdMetricsChart />, visible: true, label: 'Gasto en publicidad 1', props: { selectedOptionProp: { value: 'adSpend', label: 'Gasto en publicidad' } } },
    ]);
    const [orginalCharts, setOriginalCharts] = useState([...charts]);
    const [dashboardAnchorEl, setDashboardAnchorEl] = useState(null);
    const [properGreet, setProperGreet] = useState('');
    const [loading1, setLoading1] = useState(true);
    const [menuVisible, setMenuVisible] = useState(false);
    const [anchorPosition, setAnchorPosition] = useState({ x: 0, y: 0 });
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMenuClick = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setAnchorPosition({
            x: rect.left, // O quizás rect.right, dependiendo de dónde desees que aparezca el menú
            y: rect.top + window.scrollY,
        });
        setMenuVisible(!menuVisible);
    };

    const saveInitialChartOrder = async () => {
        const uid = auth.currentUser?.uid;
        if (!uid) return;

        const userDocRef = doc(db, `users/${uid}`);
        await setDoc(userDocRef, {
            chartsOrder_T: orginalCharts.map(chart => chart.id),
            // charts.map(chart => chart.id),
            chartsVisibility_T: orginalCharts.map(chart => chart.visible)
            // charts.map(chart => chart.visible)
        }, { merge: true });
    };

    useEffect(() => {
        if (loading1) return; // Asegúrarnos de que no se ejecute mientras se cargan los datos iniciales

        const saveChartsState = async () => {
            const uid = auth.currentUser?.uid;
            if (!uid) return;

            const userDocRef = doc(db, `users/${uid}`);
            await setDoc(userDocRef, {
                chartsOrder_T: charts.map(chart => chart.id),
                chartsVisibility_T: charts.map(chart => chart.visible)
            }, { merge: true });
        };

        saveChartsState();
    }, [charts]); // Observar cambios en charts para actualizar Firestore    

    useEffect(() => {
        const fetchChartOrder = async () => {
            try {
                const uid = auth.currentUser?.uid;
                if (!uid) return;

                const userDocRef = doc(db, `users/${uid}`);
                const docSnap = await getDoc(userDocRef);

                if (docSnap.exists()) {
                    const { chartsOrder_T } = docSnap.data();
                    if (chartsOrder_T) {
                        // Reordenar `charts` basado en `chartsOrder`
                        let orderedCharts = chartsOrder_T.map(id => charts.find(chart => chart.id === id)).filter(Boolean);
                        // si stores y stores.length y stores.length < 1, filtrar las que tengan tipo BarChartComponent
                        if (stores && stores.length && stores.length < 2) {
                            orderedCharts = orderedCharts.filter(chart => chart.type !== 'BarChartComponent');
                        }

                        // if none of the stores id start with ml_ then filter those with type MeLiMetrics
                        if (stores && stores.length && !stores.some(store => store.id.startsWith('ml_'))) {
                            orderedCharts = orderedCharts.filter(chart => chart.type !== 'MeLiMetrics');
                        }

                        setCharts(orderedCharts);
                    }
                }
            } catch (error) {
                console.error('Error fetching chart order:', error);
            } finally {

                setLoading1(false);
            }
        };

        fetchChartOrder();
    }, []);

    useEffect(() => {
        const fetchAndInitializeChartOrderAndVisibility = async () => {
            const uid = auth.currentUser?.uid;
            if (!uid) return;

            const userDocRef = doc(db, `users/${uid}`);
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists() && docSnap.data().chartsOrder_T && docSnap.data().chartsVisibility_T) {
                // Si el documento existe y los campos chartsOrder_T y chartsVisibility_T también existen, establece el orden
                const { chartsOrder_T, chartsVisibility_T } = docSnap.data();
                let orderedAndVisibleCharts = chartsOrder_T.map((id, index) => {
                    const chart = charts.find(chart => chart.id === id);
                    return chart ? { ...chart, visible: chartsVisibility_T[index] } : null;
                }).filter(Boolean);

                // si stores y stores.length y stores.length < 1, filtrar las que tengan tipo BarChartComponent
                if (stores && stores.length && stores.length < 2) {
                    orderedAndVisibleCharts = orderedAndVisibleCharts.filter(chart => chart.type !== 'BarChartComponent');
                }

                if (stores && stores.length && !stores.some(store => store.id.startsWith('ml_'))) {
                    orderedAndVisibleCharts = orderedAndVisibleCharts.filter(chart => chart.type !== 'MeLiMetrics');
                }

                setCharts(orderedAndVisibleCharts);
            } else {
                // Si no existen los campos, llama a saveInitialChartOrder para crearlos
                await saveInitialChartOrder();
            }
        };

        fetchAndInitializeChartOrderAndVisibility();
    }, []);

    useEffect(() => {
        document.title = 'Tablero | Kompa';

        const date = new Date();
        const hours = date.getHours();

        let properGreet = '';

        if (hours >= 0 && hours < 12) {
            properGreet = 'Buenos días';
        } else if (hours >= 12 && hours < 18) {
            properGreet = 'Buenas tardes';
        } else {
            properGreet = 'Buenas noches';
        }

        setProperGreet(properGreet);

    }, []);

    const [lastScrollTop, setLastScrollTop] = useState(0);

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop;  // Obtiene la posición actual del scroll
        if (scrollTop < lastScrollTop) {
            // scrollear hasta arriba el big-container, suavemente
            const bigContainer = document.getElementById('big-container');
            bigContainer.scrollTop = 0;

        }
        setLastScrollTop(scrollTop);  // Actualiza la última posición del scroll
    };

    useEffect(() => {
        if (loading1) return;

        const contentToPdf = document.getElementById('content-to-pdf');
        if (!contentToPdf) return;
        contentToPdf.addEventListener('scroll', handleScroll);

        return () => {
            contentToPdf.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop, loading1]);


    if (loading1) {
        return <div className='h-screen flex justify-center items-center bg-gray-100'>
            <div className='spinner' />
        </div>;
    }
    return (
        <>
            <CustomMenu charts={charts} setCharts={setCharts} anchorPosition={anchorPosition} menuIsVisible={menuVisible} setMenuIsVisible={setMenuVisible} />

            <Menu
                id="dashboard-menu"
                anchorEl={dashboardAnchorEl}
                keepMounted
                open={Boolean(dashboardAnchorEl)}
                onClose={() => setDashboardAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <p className="mx-4 my-2 select-none text-kompamxblue text-sm font-semibold">
                    Ocultar o Reordenar Gráficas
                </p>

                <Divider />

                {charts.map((chart, index) => (
                    <MenuItem key={chart.id} onClick={() => {
                        // Actualizar la visibilidad del gráfico específico
                        let updatedCharts = charts.map((c, i) => {
                            if (i === index) {
                                return { ...c, visible: !c.visible };
                            }
                            return c;
                        });

                        // if stores and stores.length and stores.length < 1, filter those with type BarChartComponent
                        if (stores && stores.length && stores.length < 2) {
                            // setCharts(updatedCharts.filter(chart => chart.type !== 'BarChartComponent'));

                            // return;
                            updatedCharts = updatedCharts.filter(chart => chart.type !== 'BarChartComponent');
                        }

                        // if none of the stores id start with ml_ then filter those with type MeLiMetrics
                        if (stores && stores.length && !stores.some(store => store.id.startsWith('ml_'))) {
                            // setCharts(updatedCharts.filter(chart => chart.type !== 'MeLiMetrics'));
                            // return;

                            updatedCharts = updatedCharts.filter(chart => chart.type !== 'MeLiMetrics');
                        }

                        setCharts(updatedCharts);
                    }}>
                        <div className='flex flex-row justify-start items-center w-full'>
                            <DragIndicator className='text-kompamxblue opacity-20 hover:opacity-100 transition-all duration-300' />
                            <div className='flex flex-row justify-start items-center'>
                                <Checkbox checked={chart.visible} />
                                {chart.label}
                            </div>
                        </div>
                    </MenuItem>
                ))}
            </Menu>

            <div id='big-container' style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>
                <div id='content-to-pdf' className='flex flex-col justify-start items-center bg-gray-100 pb-4 px-0' style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>

                    {screenWidth > 768 ? (
                        Array.from({
                            // Primero filtra los gráficos para incluir solo los visibles, luego calcula la longitud necesaria para el nuevo array basado en los gráficos visibles.
                            length: Math.ceil(charts.filter(chart => chart.visible).length / 2)
                        }).map((_, rowIndex) => {
                            // A partir de los gráficos visibles, obtén los que corresponden a esta fila en particular.
                            const rowCharts = charts
                                .filter(chart => chart.visible)
                                .slice(rowIndex * 2, rowIndex * 2 + 2);

                            return (
                                <div key={rowIndex} className='flex flex-row justify-center items-center w-full'>
                                    {rowCharts.map((chart, index) => (
                                        <div key={index}>
                                            {React.cloneElement(chart.content, { ...chart.props, key: `chart-${index}` })}
                                        </div>
                                    ))}
                                </div>
                            );
                        })
                    ) : (
                        charts.filter(chart => chart.visible).map((chart, index) => (
                            <div key={`container-${index}`} className='flex flex-row justify-center items-center w-full'>
                                {React.cloneElement(chart.content, { ...chart.props, key: `chart-${index}` })}
                            </div>
                        ))
                    )}
                </div>
                <Footer />
            </div>
        </>
    );
}
