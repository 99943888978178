import { useState } from 'react'
import { Boton } from '../../componentes/Boton'
import { CampoTexto } from '../../componentes/Campos'
import { Logo } from '../../componentes/Logo'
import { DisenoFino } from '../../componentes/DisenoFino'
import { Link } from 'react-router-dom'
import { auth } from '../../helpers/firebase'
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';

export const metadata = {
  title: 'Iniciar Sesión',
}

export default function IniciarSesion() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [errorColor, setErrorColor] = useState('red');

  const handleSignIn = () => {
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setLoading(false);
        return;
      })
      .catch((error) => {
        // Manejar errores aquí, como usuario no encontrado o contraseña incorrecta
        console.error(error.code, error.message);
        setLoading(false);
        setErrorColor('red')
        // Actualizar el estado de la aplicación para mostrar un mensaje de error al usuario
        if (error.code === 'auth/wrong-password') {
          setError('La contraseña es incorrecta');
        } else if (error.code === 'auth/user-not-found') {
          setError('No hay ningún usuario registrado con el correo electrónico que proporcionaste');
        } else if (error.code === 'auth/invalid-email') {
          setError('El correo electrónico que proporcionaste no es válido');
        } else if (error.code === 'auth/invalid-login-credentials') {
          setError('El correo electrónico o la contraseña que proporcionaste no son válidos');
        } else {
          // mostramos un mensaje de error generico
          setError('Error al iniciar sesión');
        }
      });
  };

  const handleForgotPassword = () => {
    if (!email) {
      setError('Por favor, introduzca su dirección de correo electrónico antes de restablecer su contraseña.');
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log('Correo de restablecimiento enviado a:', email);
        setError('Se ha enviado un correo electrónico a su bandeja de entrada. Por favor, haga clic en el enlace enviado para restablecer su contraseña. Si no encuentra el correo en su bandeja de entrada, le recomendamos revisar también su carpeta de correos no deseados o spam.');
        setErrorColor('blue')
      })
      .catch((error) => {
        setErrorColor('red')
        if (error.code === 'auth/invalid-email') {
          setError('La dirección de correo electrónico ingresada no es válida. Inténtelo de nuevo.');
        } else if (error.code === 'auth/user-not-found') {
          setError('No hay ningún usuario registrado con el correo electrónico que proporcionaste');
        } else {
          setError('Error al enviar correo de restablecimiento');
        }
      });
  };

  return (
    <DisenoFino>
      <div className="flex">
        <Link to="/" aria-label="Inicio">
          <Logo className="h-10 w-auto" />
        </Link>
      </div>
      <h2 className="mt-20 text-lg font-semibold text-gray-900">
        Inicia sesión en tu cuenta
      </h2>
      <p className="mt-2 text-sm text-gray-700">
        ¿No tienes una cuenta?{' '}
        <Link
          to="/registro"
          className="font-medium text-blue-600 hover:underline"
        >
          Regístrate
        </Link>{' '}
        para una prueba gratuita.
      </p>
      <form action="#" className="mt-10 grid grid-cols-1 gap-y-8">
        <CampoTexto
          etiqueta="Correo electrónico"
          nombre="email"
          tipo="email"
          autoComplete="email"
          required
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <CampoTexto
          etiqueta="Contraseña"
          nombre="password"
          tipo="password"
          autoComplete="current-password"
          required
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <div>
          <Boton
            // type="submit" 
            variante="solido"
            color="azul"
            className="w-full"
            onClick={handleSignIn}
          >
            <span>
              Iniciar sesión <span aria-hidden="true">&rarr;</span>
            </span>
          </Boton>
        </div>
        <div className="text-center">
          <Link
            className="font-medium text-blue-600 hover:underline"
            onClick={handleForgotPassword}
          >
            ¿Olvidaste tu contraseña?
          </Link>
        </div>
        <div className="text-center">
          <p className={`text-sm text-${errorColor}-600`}>{error}</p>
        </div>
      </form>
    </DisenoFino>
  )
}