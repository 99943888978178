import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import TopBar from './components/TopBar';
import Tablero from './pages/sidebar/Tablero';
import Informes from './pages/Informes';
import AgregarTienda from './pages/AgregarTienda';
import AmazonConnect from './pages/auth/AmazonConnect';
import MercadoLibreConnect from './pages/auth/MercadoLibreConnect';
import "./helpers/spinner.css";
import './helpers/App.css';
import TestDnDComponent from './components/TestDndComponent';
import Ads from './pages/Ads';
import RelacionarTiendas from './pages/RelacionarTiendas';
import Sidebar from './components/Sidebar';
import 'react-toastify/dist/ReactToastify.css';
import GraficosYAnalisisMT from './pages/sidebar/mistiendas/GraficosYAnalisisMT';
import MetricasDeReputacionMT from './pages/sidebar/mistiendas/MetricasDeReputacionMT';
import TablaDeProductosMT from './pages/sidebar/mistiendas/TablaDeProductosMT';
import ComparativaDeProductosC from './pages/sidebar/comparador/ComparativaDeProductosC';
import GraficosYAnalisisC from './pages/sidebar/comparador/GraficosYAnalisisC';
import GraficosPorProductoC from './pages/sidebar/comparador/GraficosPorProductoC';
import Chat from './pages/Chat';
import { TopbarProvider } from './helpers/TopbarContext';
import TaskViewer from './pages/TaskViewer';
import useStores from './hooks/useStores';
import OnBoarding from './pages/OnBoarding';
import NotFound from './pages/NotFound';
import './styles/tailwind.css';
import './styles/fonts.css'; // Necesitarás crear este archivo
import { Helmet } from 'react-helmet';
import IniciarSesion from './pages/(auth)/IniciarSesion';
import Registro from './pages/(auth)/Registro';
import StripeCheckout from './componentes/StripeCheckout';

function RootLayout({ children }) {
  return (
    <div className="flex h-full flex-col bg-white antialiased">
      {children}
    </div>
  );
}

function App() {
  return (
    <>
      <Helmet>
        <html lang="es" className="h-full scroll-smooth" />
        <title>Kompa - Tu socio inteligente en e-commerce</title>
        <meta name="description" content="Datos que impulsan tu negocio: conoce, analiza y crece con Kompa. Tu socio inteligente en e-commerce." />
      </Helmet>
      <RootLayout>
        <Router>
          <TopbarProvider>
            <MainComponent />
          </TopbarProvider>
        </Router>
      </RootLayout>
    </>
  );
}

function MainComponent() {
  const location = useLocation();
  const [hideSidebar, setHideSidebar] = useState(false);
  const [hideTopBar, setHideTopBar] = useState(false);

  const [store, setStore] = useState(null);

  const { stores, loadingStores } = useStores();
  const { loadingUser, currentUser } = useAuth();

  useEffect(() => {
    if (location.pathname !== "/amazon-connect" && location.pathname !== "/meli-connect") {
      setHideSidebar(false);
    }
    if (location.pathname === "/aichat") {
      setHideTopBar(true);
    } else {
      setHideTopBar(false);
    }
  }, [location.pathname]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (loadingUser || loadingStores) {
    return (
      <div className="flex justify-center items-center h-screen w-screen bg-gray-100">
        <div className="spinner" />
      </div >
    );
  }

  return (
    <>

      <div className="flex">
        {(!hideSidebar && (currentUser && currentUser.user && currentUser.user && (stores.length > 0) && !loadingStores)) && (
          <div>
            <Sidebar />
          </div>
        )}
        <div className={`flex-grow ${screenWidth > 768 && (currentUser && currentUser.user && currentUser.user && (stores.length > 0) && !loadingStores) && !hideSidebar && 'pl-[74px]'}`}>
          {currentUser && currentUser.user && currentUser.user && <TopBar
            hideTopBar={hideTopBar}
            setHideTopBar={setHideTopBar}
            store={store}
            setStore={setStore}
          />}
          <Routes>
            {(currentUser && currentUser.user) ? (loadingStores || stores.length < 1) ? (
              <>
                <Route path="*" element={<AgregarTienda />} />
                <Route path="amazon-connect" element={<AmazonConnect
                  hideSidebar={hideSidebar}
                  setHideSidebar={setHideSidebar} />} />
                <Route path="ml-connect" element={<MercadoLibreConnect
                  hideSidebar={hideSidebar}
                  setHideSidebar={setHideSidebar} />} />
              </>
            ) : (
              <>
                <Route exact path="/" element={<Tablero />} />
                <Route path="tablero" element={<Tablero />} />
                <Route path="informes" element={<Informes />} />
                <Route path="agregar-tienda" element={<AgregarTienda />} />
                <Route path="amazon-connect" element={<AmazonConnect
                  hideSidebar={hideSidebar}
                  setHideSidebar={setHideSidebar}
                />}
                />
                <Route path="ml-connect" element={<MercadoLibreConnect
                  hideSidebar={hideSidebar}
                  setHideSidebar={setHideSidebar}
                />} />
                <Route path="relacionar-tiendas" element={<RelacionarTiendas />} />
                <Route path="dnd" element={<TestDnDComponent />} />
                <Route path="ads" element={<Ads />} />
                <Route path="tasks" element={<TaskViewer />} />


                <Route path="aichat" element={<Chat />} />

                <Route path="/mis-tiendas/graficos" element={<GraficosYAnalisisMT
                  store={store}
                />} />
                <Route path="/mis-tiendas/tabla-de-productos" element={<TablaDeProductosMT
                  store={store}
                />} />
                <Route path="/mis-tiendas/metricas-de-reputacion" element={<MetricasDeReputacionMT
                  store={store}
                />} />

                <Route path="/comparador/graficos" element={<GraficosYAnalisisC />} />
                <Route path="/comparador/comparativa-de-productos" element={<ComparativaDeProductosC />} />
                <Route path="/comparador/graficos-por-producto" element={<GraficosPorProductoC />} />
                <Route path="*" element={<Tablero />} />
              </>
            ) : (
              <>
                <Route path="/" element={<OnBoarding />} />
                {/* 
                <Route path="/" element={<IniciarSesion />} />
                <Route path="*" element={<IniciarSesion />} /> 
                */}

                <Route path="iniciar-sesion" element={<IniciarSesion />} />
                <Route path="registro" element={<Registro />} />
                <Route path="pago" element={<StripeCheckout />} />
              </>
            )}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
