import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Bar, BarChart, Line, LineChart, CartesianGrid, XAxis, YAxis, Tooltip as RechartsTooltip, Legend } from 'recharts';
import Select, { components } from 'react-select';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../../helpers/firebase';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import '../../../helpers/spinner.css';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import BarChartIcon from '@mui/icons-material/BarChart';
import logoMeLi from '../../../assets/ml_trans.png';
import { ToastContainer, toast } from 'react-toastify';  // Importa ToastContainer y toast
import makeAnimated from 'react-select/animated';
import useAuth from '../../../hooks/useAuth';
import az from '../../../assets/merchantspring/az.jpg';

const animatedComponents = makeAnimated();

const NoOptionsMessage = props => {
    return (
        <components.NoOptionsMessage {...props}>
            <span className="text-gray-400">
                No hay más tiendas para seleccionar.
            </span>
        </components.NoOptionsMessage>
    );
};


const selectStyle = (color) => ({
    control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? `${color} !important` : 'inherit',
        boxShadow: state.isFocused ? `0px 0px 0px 1px ${color}` : 'none',
    })
});

const StoreComparisonChart = ({ metricProp, productIdsProp, storeIdsProp }) => {
    const [data, setData] = useState([]);

    const [metric, setMetric] = useState(metricProp || "Ventas"); // Inicia con "Ventas"

    useEffect(() => {
        if (metricProp) setMetric(metricProp);
    }, [metricProp]);

    const [graphicType, setGraphicType] = useState("BarChart"); // Inicia con "BarChart"
    const [storeNames, setStoreNames] = useState({});
    const [allStoreOptions, setAllStoreOptions] = useState([]);
    const [storeOptions, setStoreOptions] = useState({ store1: [], store2: [] });
    const [selectedStores, setSelectedStores] = useState([]);
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const { currentUser } = useAuth();

    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth * 0.5) - 50));

    const metricOptions = [
        { value: "Ventas", label: "Ventas" },
        { value: "Pedidos", label: "Pedidos" },
        { value: "AOV", label: "Valor Promedio del Pedido" },
        { value: "Unidades", label: "Unidades Vendidas" },
        { value: "TotalAcumulado", label: "Total Acumulado" }
    ];

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setChartWidth((window.innerWidth - 15));
                return;
            }
            const newChartWidth = (window.innerWidth * 0.5) - 50;
            setChartWidth(newChartWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSelectStores = (selectedOptions) => {
        if (selectedOptions.length < 2) {
            toast.error("Selecciona al menos dos tiendas.");
            return;
        }
        if (selectedOptions.length > 4) {
            toast.error("No puedes seleccionar más de cuatro tiendas.");
            return;
        }

        setSelectedStores(selectedOptions);
    };

    useEffect(() => {
        const uid = currentUser?.uid;
        if (!uid) return;

        const storeRef = collection(db, `users/${uid}/stores`);
        const fetchStores = async () => {
            setLoading1(true);

            const snapshot = await getDocs(storeRef);

            const options = snapshot.docs.map(doc => ({
                value: doc.id,
                data: doc.data(),
                label: <div
                    className='flex flex-row items-center'
                >
                    <img
                        // si empieza con ml usamos el logo de mercado libre
                        src={
                            doc.id.startsWith('ml') ?
                                logoMeLi :
                                doc.id.startsWith('az') &&
                                az
                        }
                        style={{ width: 20, height: 20, marginRight: 10 }}
                        alt=""
                    />
                    <div
                        style={{ backgroundColor: doc.data().color || '#ccc', width: 10, height: 10, borderRadius: '50%', marginRight: 10 }}
                    />

                    {doc.data().custom_name || doc.id}
                </div>

            }));

            setAllStoreOptions(options);

            if (snapshot.docs.length > 1 && !storeIdsProp) {
                setSelectedStores([options[0], options[1]]);
            } else if (storeIdsProp) {
                const selectedStoresFromProp = options.filter(option => storeIdsProp.includes(option.value));
                setSelectedStores(selectedStoresFromProp);
            }

            setLoading1(false);
        };

        fetchStores();
    }, [currentUser, storeIdsProp]);

    useEffect(() => {
        if (!allStoreOptions.length) return;

        if (!selectedStores.length) {
            return;
        }

        const store1 = allStoreOptions.find(option => option.value === selectedStores.store1?.value);
        const store2 = allStoreOptions.find(option => option.value === selectedStores.store2?.value);

        setStoreOptions({
            store1: allStoreOptions.filter(option => option.value !== store2?.value),
            store2: allStoreOptions.filter(option => option.value !== store1?.value),
        });

    }, [allStoreOptions, selectedStores]);

    useEffect(() => {
        const allMetricsData = {};

        const fetchData = async () => {
            if (!currentUser) return;

            setLoading1(true);
            setLoading2(true);

            const uid = currentUser?.uid;
            if (!uid) {
                console.log("No UID found, aborting fetch.");
                return;
            }

            const storeRef = collection(db, `users/${uid}/stores`);
            const snapshot = await getDocs(storeRef);
            if (snapshot.empty) {
                console.log("No stores found for user:", uid);
                setLoading1(false);
                setLoading2(false);
                return;
            }

            const storeData = snapshot.docs.map(doc => ({
                value: doc.id,
                data: doc.data(),
                label: <div className='flex flex-row items-center'>
                    <img src={doc.id.startsWith('ml') ? logoMeLi : az}
                        style={{ width: 20, height: 20, marginRight: 10 }}
                        alt="" />
                    <div style={{ backgroundColor: doc.data().color || '#ccc', width: 10, height: 10, borderRadius: '50%', marginRight: 10 }} />
                    {doc.data().custom_name || doc.id}
                </div>
            }));

            setAllStoreOptions(storeData);

            const promises = storeData.map(store => processStoreData(store, uid));
            await Promise.all(promises);

            setData(allMetricsData);
            setLoading1(false);
            setLoading2(false);
        };

        async function processStoreData(store, uid) {
            const ordersRef = collection(db, `users/${uid}/stores/${store.value}/orders`);
            const start = new Date(new Date().getFullYear() - 1, 0, 1); // Start from January of last year
            const q = query(ordersRef, where('order_creation_date', '>=', start.toISOString()));
            const querySnapshot = await getDocs(q);

            // get store name
            const storeDoc = doc(db, `users/${uid}/stores/${store.value}`);
            const storeData = await getDoc(storeDoc);
            const storeName = storeData.data().custom_name || store.value;
            setStoreNames(prev => ({ ...prev, [store.value]: storeName }));

            const metrics = {
                Ventas: Array(12).fill(0),
                Pedidos: Array(12).fill(0),
                AOV: Array(12).fill(0),
                Unidades: Array(12).fill(0),
                TotalAcumulado: Array(12).fill(0) // Inicializa el array para total acumulado
            };

            let acumulado = 0; // Variable para mantener el total acumulado

            querySnapshot.forEach(doc => {
                const data = doc.data();
                const orderDate = new Date(data.order_creation_date);
                const monthIndex = orderDate.getMonth(); // Get month index directly

                // if productIdsProp is passed, filter the data
                if (productIdsProp) {
                    if (!data.order_items) return;
                    // where any element of array order_items contains a property item that contains a property id that is in the productIdsProp array
                    // or any element of  array order_items contains a property ASIN that is in the productIdsProp array

                    if (!data.order_items.some(item => (item?.item?.id && productIdsProp.includes(item.item.id)) || (item?.ASIN && productIdsProp.includes(item.ASIN)))) {
                        return;
                    }

                    // get item
                    const item = data.order_items.find(item => (item?.item?.id && productIdsProp.includes(item.item.id)) || (item?.ASIN && productIdsProp.includes(item.ASIN)));

                    // ad just the amount of the order that contains the product .ItemPrice.Amount * QuantityShipped for Amazon and quantity * full_unit_price for MercadoLibre
                    metrics.Ventas[monthIndex] += parseFloat(item?.full_unit_price || item?.ItemPrice?.Amount) * parseFloat(item?.quantity || item?.QuantityShipped) || 0;
                    metrics.Unidades[monthIndex] += parseFloat(item?.quantity || item?.QuantityShipped) || 0;
                    metrics.AOV[monthIndex] += parseFloat(item?.full_unit_price || item?.ItemPrice?.Amount) * parseFloat(item?.quantity || item?.QuantityShipped) || 0; // We will average this later

                    acumulado += parseFloat(item?.full_unit_price || item?.ItemPrice?.Amount) * parseFloat(item?.quantity || item?.QuantityShipped) || 0;
                    metrics.TotalAcumulado[monthIndex] = acumulado; // Asigna el total acumulado
                } else {
                    metrics.Ventas[monthIndex] += parseFloat(data.total_order_amount) || 0;
                    metrics.Unidades[monthIndex] += parseFloat(data.unidades_vendidas) || 0;
                    metrics.AOV[monthIndex] += parseFloat(data.total_order_amount) || 0; // We will average this later

                    acumulado += parseFloat(data.total_order_amount) || 0;
                    metrics.TotalAcumulado[monthIndex] = acumulado; // Asigna el total acumulado
                }
                metrics.Pedidos[monthIndex] += 1;
            });

            // Average AOV
            metrics.AOV = metrics.AOV.map((total, index) => {
                return metrics.Pedidos[index] > 0 ? total / metrics.Pedidos[index] : 0;
            });

            allMetricsData[store.value] = metrics;
        }

        fetchData();

    }, [productIdsProp, currentUser]);

    const renderChartData = useMemo(() => {
        // Asegúrate de que haya al menos dos tiendas seleccionadas y que cada una tenga datos cargados
        if (selectedStores.length < 2 || !selectedStores.every(store => data[store.value])) {
            return [];
        }

        const currentMonth = new Date().getMonth();
        const chartData = Array.from({ length: 12 }, (_, i) => {
            const adjustedMonthIndex = (currentMonth - i + 12) % 12;
            let entry = { month: adjustedMonthIndex };
            selectedStores.forEach(store => {
                const metricData = data[store.value][metric];
                if (metricData && metricData[adjustedMonthIndex] !== undefined) {
                    entry[store.value] = metricData[adjustedMonthIndex];
                }
            });
            return entry;
        }).reverse();
        return chartData;
    }, [data, metric, selectedStores]);

    const totalsByStore = useMemo(() => {
        const totals = {};
        renderChartData.forEach(monthData => {
            Object.entries(monthData).forEach(([key, value]) => {
                if (key !== 'month') {
                    totals[key] = totals[key] || 0;  // Ensure initialization before use
                    if (metric === "TotalAcumulado") {
                        totals[key] = value;
                    } else totals[key] += value;
                }
            });
        });

        // AOV requires averaging
        if (metric === "AOV") {
            Object.keys(totals).forEach(key => {
                totals[key] = Math.round((totals[key] / 12) * 100) / 100; // Convert to average and round off
            });
        }
        return totals;
    }, [renderChartData, metric]);

    const CustomLegend = ({ payload, legendRef }) => {
        const getInitials = (name) => {
            return name
                .split(' ')
                .map(word => word[0])
                .join('')
                .toUpperCase();
        };

        const formatNumber = (number, useRounding) => {
            if (useRounding) {
                // Redondear al entero más cercano
                return Math.round(number).toLocaleString();
            } else {
                // Si es entero
                if (number % 1 === 0) {
                    return number.toLocaleString();
                }
                // Usar dos decimales
                return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 10px' }} ref={legendRef}>
                <ul style={{ display: 'flex', listStyle: 'none', padding: 0 }}>
                    {payload.map((entry, index) => (
                        <li key={`item-${index}`} style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>

                            <svg
                                width={entry.type === 'line' ? "20" : "10"}
                                height={entry.type === 'line' ? "5" : "20"}
                                style={{ verticalAlign: 'middle', marginRight: 10 }}
                            >
                                <rect
                                    width={entry.type === 'line' ? "20" : "10"}
                                    height={entry.type === 'line' ? "5" : "20"}
                                    fill={entry.color || 'lightgray'}
                                />
                            </svg>
                            <Tooltip title={payload.length > 2 ? entry.value : null}>
                                <span style={{ color: (entry.color || 'lightgray'), fontSize: 14 }}>
                                    {storeIdsProp && (payload.length > 2 ? getInitials(entry.value) : entry.value)}
                                    {storeIdsProp && <br />}
                                    <span style={{ fontSize: 14, marginLeft: 0, fontWeight: '500' }}>
                                        {(metric === "Ventas" || metric === "AOV") && "$"}{formatNumber(totalsByStore[entry.dataKey], payload.length > 2)}
                                    </span>
                                </span>
                            </Tooltip>

                        </li>

                    ))}
                </ul>
            </div >
        );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const formatCurrency = (value) => {
                return value.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                });
            };

            const translateMonth = (month) => {
                const months = {
                    0: 'Enero', 1: 'Febrero', 2: 'Marzo', 3: 'Abril', 4: 'Mayo', 5: 'Junio',
                    6: 'Julio', 7: 'Agosto', 8: 'Septiembre', 9: 'Octubre', 10: 'Noviembre', 11: 'Diciembre'
                };
                return months[month] || month;
            };

            const getLogo = (dataKey) => {
                if (dataKey.includes('az')) {
                    return az;
                    // URL de la imagen de Amazon
                } else {
                    return logoMeLi; // URL de la imagen de MercadoLibre
                }
            };

            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                    <p className="label font-medium mb-2">
                        {translateMonth(label)}
                    </p>
                    {payload.map((entry, index) => (
                        <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={getLogo(entry.dataKey)} alt="logo" style={{ width: 20, height: 20, marginRight: 10 }} />
                            <span style={{ marginRight: 5, }}>
                                {entry.name}:
                            </span>
                            <span style={{ fontWeight: '500' }}>
                                {(metric === "Ventas" || metric === "AOV" || metric === "TotalAcumulado"

                                ) ? formatCurrency(entry.value) : entry.value}
                            </span>
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    const formatCurrency = (value) => {
        return `$${value.toLocaleString()}`;
    };

    const MONTHS = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const renderChart = () => {
        if (graphicType === "BarChart") {
            return (
                <BarChart
                    width={chartWidth - 45}
                    height={chartHeight}
                    data={renderChartData}
                    margin={{ left: (metric === "TotalAcumulado") ? 20 : (metric === "Ventas") ? 0 : -20 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" tickFormatter={(index) => MONTHS[index].slice(0, 3)} />
                    <YAxis tickFormatter={metric === "Ventas" ? formatCurrency : metric === "AOV" ? formatCurrency : metric === "Unidades" ? (value) => value.toLocaleString() : metric === "TotalAcumulado" ? formatCurrency : null} width={70} />
                    <RechartsTooltip content={<CustomTooltip />} />
                    <Legend content={(props) => <CustomLegend {...props} legendRef={legendRef} />} />
                    {selectedStores.map(store => (
                        <Bar
                            key={store.value}
                            dataKey={store.value}
                            fill={store?.data?.color || 'lightgray'}
                            name={storeNames[store.value]}
                        />
                    ))}
                </BarChart>
            );
        } else if (graphicType === "LineChart") {
            return (
                <LineChart
                    width={chartWidth - 45}
                    height={chartHeight}
                    data={renderChartData}
                    margin={{ left: (metric === "TotalAcumulado") ? 20 : (metric === "Ventas") ? 0 : -20 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />

                    <XAxis dataKey="month" tickFormatter={(index) => MONTHS[index].slice(0, 3)} />
                    <YAxis tickFormatter={metric === "Ventas" ? formatCurrency : metric === "AOV" ? formatCurrency : metric === "Unidades" ? (value) => value.toLocaleString() : metric === "TotalAcumulado" ? formatCurrency : null} width={70} />
                    <Legend content={(props) => <CustomLegend {...props} legendRef={legendRef} />} />
                    <RechartsTooltip content={<CustomTooltip />} />
                    {selectedStores.map(store => (
                        <Line
                            key={store.value}
                            type="monotone"
                            dataKey={store.value}
                            stroke={store?.data?.color || 'lightgray'}
                            strokeWidth={2}
                            dot={false}
                            name={storeNames[store.value] || store.value}
                        />
                    ))}
                </LineChart>
            );
        }
    };

    const legendRef = useRef(null);
    const selectRef = useRef(null);
    const headerRef = useRef(null);
    const [selectHeight, setSelectHeight] = useState(0);
    const [legendHeight, setLegendHeight] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(0);

    useEffect(() => {
        if (legendRef.current) {
            const measureLegendHeight = () => {
                const legendHeight = legendRef.current.getBoundingClientRect().height;
                setLegendHeight(legendHeight);
            };

            measureLegendHeight();

            const resizeObserver = new ResizeObserver(measureLegendHeight);
            resizeObserver.observe(legendRef.current);

            return () => resizeObserver.disconnect();
        }
    }, [legendRef.current]);

    useEffect(() => {
        if (selectRef.current) {
            const measureSelectHeight = () => {
                const selectHeight = selectRef.current.getBoundingClientRect().height;
                setSelectHeight(selectHeight);
            };

            measureSelectHeight();

            const resizeObserver = new ResizeObserver(measureSelectHeight);
            resizeObserver.observe(selectRef.current);

            return () => resizeObserver.disconnect();
        }
    }, [selectRef.current]);

    useEffect(() => {
        if (headerRef.current) {
            const measureHeaderHeight = () => {
                const headerHeight = headerRef.current.getBoundingClientRect().height;
                setHeaderHeight(headerHeight);
            };

            measureHeaderHeight();

            const resizeObserver = new ResizeObserver(measureHeaderHeight);
            resizeObserver.observe(headerRef.current);

            return () => resizeObserver.disconnect();
        }
    }, [headerRef.current]);

    const chartHeight = 420 - ((selectHeight + (legendHeight || 41) + (headerHeight || 56)));

    return (
        (allStoreOptions.length < 2 && !storeIdsProp && !loading1) ?
            <div className="flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1" style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
                <div ref={headerRef} className="flex flex-col md:flex-row items-center px-5 text-kompamxblue text-base justify-center md:justify-between w-full" style={{ minHeight: "3.5rem" }}>
                    <div className="flex items-center justify-between w-full">

                        <div className="font-semibold mr-2">
                            Comparativa de {metric === "Ventas" ? "Ventas" : metric === "Pedidos" ? "Pedidos" : metric === "AOV" ? "Valor Promedio de Pedido" : metric === "Unidades" ? "Unidades Vendidas" : "Total Acumulado"}
                        </div>
                        <Tooltip title={metric === "Ventas" ? `Comparación de ventas entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.` : metric === "Pedidos" ? `Comparación de pedidos entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.` : metric === "AOV" ? `Comparación del valor promedio de pedido entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.` : metric === "Unidades" ? `Comparación de unidades vendidas entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.` : `Comparación del total acumulado entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.`}>
                            <InfoOutlined style={{ fontSize: 18 }} className='ml-auto' />
                        </Tooltip>
                    </div>
                </div>
                <hr className="w-full border-gray-300" />
                <div className="flex items-center md:mt-0 w-full h-full justify-center px-2 mb-2">
                    <p className="text-gray-500 text-lg">
                        Selecciona al menos dos tiendas para comparar.
                    </p>
                </div>
            </div > :
            <div className="flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1" style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
                <div ref={headerRef} className="flex flex-row items-center px-5 text-kompamxblue text-base justify-center md:justify-between w-full" style={{ minHeight: "3.5rem" }}>
                    <div className="flex flex-row items-center justify-between w-full">
                        <div className="flex items-center">
                            <div className="font-semibold mr-2">
                                Comparativa de {metric === "Ventas" ? "Ventas" : metric === "Pedidos" ? "Pedidos" : metric === "AOV" ? "Valor Promedio de Pedido" : metric === "Unidades" ? "Unidades Vendidas" : "Total Acumulado"}
                            </div>
                            <Tooltip title={metric === "Ventas" ? `Comparación de ventas entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.` : metric === "Pedidos" ? `Comparación de pedidos entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.` : metric === "AOV" ? `Comparación del valor promedio de pedido entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.` : metric === "Unidades" ? `Comparación de unidades vendidas entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.` : `Comparación del total acumulado entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.`}>
                                <InfoOutlined style={{ fontSize: 18 }} className='ml-auto' />
                            </Tooltip>
                        </div>
                        {!metricProp &&
                            <Select
                                className="ml-auto w-32 mr-2"
                                options={metricOptions}
                                value={metricOptions.find(option => option.value === metric)}
                                onChange={(option) => setMetric(option.value)}
                                placeholder="Métrica"

                            />
                        }
                        <div className="flex flex-col md:flex-row">
                            <Tooltip title="Ver gráfico de barras">
                                <BarChartIcon
                                    style={{ fontSize: 24, cursor: 'pointer', color: graphicType === "BarChart" ? 'inherit' : 'lightgray' }}
                                    className='mr-2'
                                    onClick={() => setGraphicType("BarChart")}
                                />
                            </Tooltip>
                            <Tooltip title="Ver gráfico de líneas">
                                <LineAxisIcon
                                    style={{ fontSize: 24, cursor: 'pointer', color: graphicType === "LineChart" ? 'inherit' : 'lightgray' }}
                                    onClick={() => setGraphicType("LineChart")}
                                />
                            </Tooltip>
                        </div>

                    </div>
                </div>
                <hr className="w-full border-gray-300" />

                {!storeIdsProp && allStoreOptions.length > 2 &&
                    <div className="flex items-center w-full justify-center px-2 py-2" ref={selectRef}>
                        <Select
                            components={{ ...animatedComponents, NoOptionsMessage }}
                            isMulti
                            options={allStoreOptions}
                            value={selectedStores}
                            onChange={handleSelectStores}
                            placeholder="Selecciona tiendas"
                            isClearable={false}
                            styles={{
                                ...selectStyle(),
                                multiValue: (styles) => ({ ...styles, backgroundColor: '#f0f0f0' }),
                                multiValueLabel: (styles) => ({ ...styles, color: 'black' }),
                                multiValueRemove: (styles) => ({ ...styles, color: 'black', ':hover': { backgroundColor: 'red', color: 'white' } }),
                            }}
                        />
                    </div>
                }
                {
                    !loading1 && !loading2 ?
                        renderChartData.length ?
                            <div className="flex items-center justify-center w-full h-full">
                                {renderChart()}
                            </div>
                            :
                            <div className="flex items-center justify-center h-full w-full">
                                <p className="text-gray-500 text-lg">
                                    No hay datos para mostrar
                                </p>
                            </div>
                        :
                        <div
                            className="flex items-center justify-center h-full w-full"
                        >
                            <div className='spinner' />
                        </div>
                }
                <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            </div >
    );
};

export default StoreComparisonChart; 
