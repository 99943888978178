import React, { useState } from 'react';
import Draggable from 'react-draggable';

const initialItems = [
    { id: 'item-1', content: 'Item 1' },
    { id: 'item-2', content: 'Item 2' },
    { id: 'item-3', content: 'Item 3' },
    { id: 'item-4', content: 'Item 4' },
];

const TestDnDComponent = () => {
    const [items, setItems] = useState(initialItems);

    const moveItem = (dragIndex, hoverIndex) => {
        const draggedItem = items[dragIndex];
        const newItems = [...items];
        newItems.splice(dragIndex, 1);
        newItems.splice(hoverIndex, 0, draggedItem);
        setItems(newItems);
    };

    const handleDrag = (dragIndex, hoverIndex) => {
        moveItem(dragIndex, hoverIndex);
    };

    return (
        <div>
            {items.map((item, index) => (
                <Draggable
                    key={item.id}
                    onStop={() => handleDrag(index, index)} // Llamamos a handleDrag cuando se suelta el elemento
                >
                    <div>
                        {item.content}
                    </div>
                </Draggable>
            ))}
        </div>
    );
};

export default TestDnDComponent;
