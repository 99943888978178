import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { DisenoFino } from './DisenoFino';

const STRIPE_PUBLISHABLE_KEY = 'pk_test_51Q4jfBRuAsA43oAAJ550BzAWFWDXfeX6CqKM2aTkX6Tn5bzSKpGWizUKldYaCbJNzdIflT1oi0wdp3v6itsk3CcD00y0PAhxyW';
const PAYMENT_INTENT_URL = 'https://localhost:44357/spdata';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = ({ price }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        console.log('Attempting to create PaymentIntent...');
        const response = await fetch(PAYMENT_INTENT_URL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            amount: Math.round(price * 100),
            currency: 'usd',
          }),
        });

        console.log('Response status:', response.status);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('PaymentIntent created:', data);
        setPaymentIntent(data);
      } catch (err) {
        console.error('Error creating PaymentIntent:', err);
        setError('Failed to initialize payment. Please try again.');
      }
    };

    createPaymentIntent();
  }, [price]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setProcessing(true);

    if (!stripe || !elements || !paymentIntent) {
      setProcessing(false);
      return;
    }

    const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Jenny Rosen',
        },
      },
    });

    if (result.error) {
      console.log('[error]', result.error);
      setError(result.error.message);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded');
        // Handle successful payment here
      }
    }
    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Información de pago</h2>
        <div className="mt-5">
          <CardElement 
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </div>
        {error && <div className="text-red-600 mt-2">{error}</div>}
      </div>
      <div className="mt-6">
        <button
          type="submit"
          disabled={!stripe || processing || !paymentIntent}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
        >
          {processing ? 'Procesando...' : `Pagar $${price}`}
        </button>
      </div>
    </form>
  );
};

const StripeCheckoutWrapper = ({ price }) => (
  <DisenoFino>
    <Elements stripe={stripePromise}>
      <CheckoutForm price={price} />
    </Elements>
  </DisenoFino>
);

export default StripeCheckoutWrapper;